import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Grid,
} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EuroIcon from '@mui/icons-material/Euro';
import PercentIcon from '@mui/icons-material/Percent';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SavingsIcon from '@mui/icons-material/Savings';
import { api } from '../api/axios';
import { formatNumber } from '../utils/tools';

const SummaryCard = ({ title, value, unit, icon }) => (
  <Card
    sx={{
      borderRadius: '12px',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#f9f9f9',
      padding: '16px',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Box
      sx={{
        marginRight: '16px',
        color: '#1A73E8',
        fontSize: '2.5rem',
      }}
    >
      {icon}
    </Box>
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 500,
          color: '#555',
          marginBottom: '4px',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          color: '#1A73E8',
        }}
      >
        {value}
        {unit && (
          <Typography
            component="span"
            variant="h6"
            sx={{
              marginLeft: '4px',
              fontWeight: 400,
              color: '#1A73E8',
            }}
          >
            {unit}
          </Typography>
        )}
      </Typography>
    </Box>
  </Card>
);

const Summary = () => {
  const [summaryData, setSummaryData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/agents/grants/summary', {
          withCredentials: true,
        });
        setSummaryData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching summary data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (!summaryData) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No data available
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SummaryCard
            title="Total Fund (EUR)"
            value={formatNumber(summaryData.total_fund_eur)}
            unit="EUR"
            icon={<AccountBalanceIcon fontSize="inherit" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SummaryCard
            title="Total Fund (SDG)"
            value={formatNumber(summaryData.total_fund_sdg)}
            unit="SDG"
            icon={<SavingsIcon fontSize="inherit" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SummaryCard
            title="Remaining Fund (EUR)"
            value={formatNumber(summaryData.remaining_fund_eur)}
            unit="EUR"
            icon={<EuroIcon fontSize="inherit" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SummaryCard
            title="Remaining Fund (SDG)"
            value={formatNumber(summaryData.remaining_fund_sdg)}
            unit="SDG"
            icon={<SavingsIcon fontSize="inherit" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SummaryCard
            title="Total Utilized (EUR)"
            value={formatNumber(summaryData.total_utilized_eur)}
            unit="EUR"
            icon={<TrendingUpIcon fontSize="inherit" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SummaryCard
            title="Total Utilized (SDG)"
            value={formatNumber(summaryData.total_utilized_sdg)}
            unit="SDG"
            icon={<TrendingUpIcon fontSize="inherit" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SummaryCard
            title="Utilization Percentage"
            value={summaryData.average_utilization_percentage.toFixed(2)}
            unit="%"
            icon={<PercentIcon fontSize="inherit" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SummaryCard
            title="Euro to SDG Rate"
            value={formatNumber(summaryData.euro_to_sdg_rate)}
            unit="SDG"
            icon={<EuroIcon fontSize="inherit" />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Summary;
