import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  Button,
} from '@mui/material';
import { getGrants } from '../../api/axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const BeneficiaryDataTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getGrants();
        console.log('the returned data is: :', response);
        setRows(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const exportToExcel = () => {
    const formattedRows = rows.map((row) => ({
      'IC No.': row.ic_no,
      State: row.state,
      TIER: row.tier,
      'Term Sheet Ref': row.term_sheet_ref,
      'Beneficiary Name': row.beneficiary_name,
      'Business Name': row.business_name,
      'No. of Beneficiaries': row.num_beneficiaries,
      'Part of Group - For Disbursement': row.part_of_group,
      'Grant - EUR': formatNumber(row.grant_eur),
      'Grant - SDG': formatNumber(row.grant_sdg),
      'Utilized Grant - SDG': formatNumber(row.grant_sdg * parseFloat(row.utilization_percent) * 0.01),
      'Utilization %': formatNumber(row.utilization_percent),
      'Transaction date': row.transaction_date,
      'Transaction Ref': row.transaction_ref,
      'Transaction Description': row.transaction_desc,
      'Beneficiary Mobile': row.mobile,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Beneficiary Data');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'Beneficiary_Data.xlsx');
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '16px',
        boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.12)',
        overflowX: 'auto',
        padding: '16px',
        backgroundColor: '#F9FAFB',
      }}
    >
      <Button
        variant="contained"
        sx={{ marginBottom: '16px', backgroundColor: '#1A73E8', color: '#FFFFFF' }}
        onClick={exportToExcel}
      >
        Export to Excel
      </Button>
      <Table
        sx={{
          minWidth: 1200,
          borderCollapse: 'separate',
          borderSpacing: '0 12px',
        }}
        aria-label="enhanced table"
      >
        <TableHead
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 3,
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '2px 8px', // Further reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
                minWidth: '30px', // Set minimum width
                maxWidth: '30px', // Set maximum width
                textAlign: 'center', // Center align text
              }}
            >
              No.
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '2px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
                minWidth: '60px', // Set minimum width for IC No.
                maxWidth: '60px', // Set maximum width for IC No.
                position: 'sticky',
                left: '30px', // Adjust left position based on the "No." column width
                zIndex: 2,
              }}
            >
              IC No.
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              State
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              TIER
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Term Sheet Ref
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
                position: 'sticky',
                left: '90px', // Adjust left position based on previous columns
                zIndex: 2,
              }}
            >
              Beneficiary Name
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Business Name
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              No. of Beneficiaries
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Part of Group - For Disbursement
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Grant - EUR
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Grant - SDG
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Utilized Grant - SDG
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Utilization %
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Transaction Date
            </TableCell>

            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Transaction Ref
            </TableCell>

            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Beneficiary Mobile
            </TableCell>

            <TableCell
              sx={{
                backgroundColor: '#1A73E8',
                color: '#FFFFFF',
                fontWeight: 700,
                padding: '4px 8px', // Reduced padding
                textTransform: 'uppercase',
                fontSize: '10px', // Reduced font size
                borderBottom: '3px solid #1558B0',
              }}
            >
              Description
            </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#F0F4FF',
                    },
                    backgroundColor:
                      index % 2 === 0 ? '#FFFFFF' : '#F9FAFB', // Alternating row colors
                    borderRadius: '12px',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
                    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                    marginBottom: '12px',
                  }}
                >
                  <TableCell
                    sx={{
                      padding: '2px 8px', // Match the reduced padding
                      color: '#333333',
                      fontSize: '10px', // Match the reduced font size
                      fontWeight: 500,
                      borderBottom: 'none',
                      minWidth: '30px', // Match the header width
                      maxWidth: '30px', // Match the header width
                      textAlign: 'center', // Center-align the text
                    }}
                  >
                    {row.ID}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '2px 8px', // Match the reduced padding
                      color: '#333333',
                      fontSize: '10px', // Match the reduced font size
                      fontWeight: 500,
                      borderBottom: 'none',
                      minWidth: '60px', // Match the header width
                      maxWidth: '60px', // Match the header width
                      position: 'sticky',
                      left: '30px', // Match the header position
                      zIndex: 1,
                      backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F9FAFB', // Ensure sticky column background matches the row
                    }}
                  >
                    {row.ic_no}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.state}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.tier}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.term_sheet_ref}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                      position: 'sticky',
                      left: '90px', // Match the header position
                      zIndex: 1,
                      backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F9FAFB', // Ensure sticky column background matches the row
                    }}
                  >
                    {row.beneficiary_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.business_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.num_beneficiaries}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.part_of_group}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {formatNumber(row.grant_eur)}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {formatNumber(row.grant_sdg)}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {formatNumber(row.grant_sdg * parseFloat(row.utilization_percent) * 0.01)}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.utilization_percent + '%'}
                  </TableCell>

                  {/* transaction ref cell */}
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.transaction_date}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.transaction_ref}
                    
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.mobile}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '4px 8px',
                      color: '#333333',
                      fontSize: '10px',
                      fontWeight: 500,
                      borderBottom: 'none',
                    }}
                  >
                    {row.transaction_desc}
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={12}
                sx={{
                  padding: '24px',
                  textAlign: 'center',
                  color: '#888888',
                  fontSize: '16px',
                }}
              >
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={12}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                color: '#333333',
                backgroundColor: '#F1F3F4',
                '.MuiTablePagination-actions button': {
                  borderRadius: '50%',
                  backgroundColor: '#1A73E8',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#1558B0',
                  },
                },
              }}
              component="div"
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default BeneficiaryDataTable;