import React, { createContext, useState, useEffect } from 'react';
import { api } from "../api/axios";

export const BeneficiaryContext = createContext();

export const BeneficiaryContextProvider = props => {
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchBeneficiaries = async () => {
        try {
          const response = await api.get('/beneficiaries', { withCredentials: true });
          setBeneficiaries(response.data);
          setLoading(false);
        } catch (error) {
          console.error("Failed to fetch beneficiaries:", error);
        }
      };
  
      fetchBeneficiaries();
    }, []);
  
    return (
      <BeneficiaryContext.Provider value={{ beneficiaries, loading }}>
        {props.children}
      </BeneficiaryContext.Provider>
    );
  };