import { createContext, useEffect, useState } from "react";
import { api } from "../api/axios";

export const authContext = createContext();

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const validateSession = async () => {
    try {
      const response = await api.get('/profile_info', { withCredentials: true });
      
      if (response.status === 200) {
        // Store user data in local storage
        localStorage.setItem('user_profile', JSON.stringify(response.data));
        console.log("the response is ", response);
        setUserData(response.data); // Assuming the API returns user data
        setIsAuthenticated(true); // User is authenticated
      } else {
        setIsAuthenticated(false); // User is not authenticated
      }
    } catch (error) {
      console.log("Error fetching data, error is ", error);
      setIsAuthenticated(false); // User is not authenticated
    } finally {
      setLoading(false); // Loading is done
    }
  };

  const logout = async () => {
    try {
      // Call the backend to logout
      await api.get('/logout', { withCredentials: true });
      
      // Then clear frontend storage and state
      localStorage.removeItem('user_profile');
      setIsAuthenticated(false);
      setUserData(null);
      
      // Redirect to login page or home page as per your flow
      window.location.href = '/';
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  useEffect(() => {
    const checkAuthState = async () => {
      const userProfile = localStorage.getItem('user_profile');
      
      if (userProfile) {
        setUserData(JSON.parse(userProfile));
        setIsAuthenticated(true);
      }
      
      await validateSession();
    };
    
    checkAuthState();
  }, []);

  return (
    <authContext.Provider value={{ isAuthenticated, userData, logout, loading }}>
      {props.children}
    </authContext.Provider>
  );
};

export default AuthContextProvider;