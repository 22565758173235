export function isValidEmail(email) {
    const allowedDomains = [
      /@nil\.sd$/,
      /@tutipay\.com$/,
      /mmbusif@gmail\.com$/,
      /@249startups\.com$/
    ];
  
    for (const domain of allowedDomains) {
      if (domain.test(email)) {
        return true;
      }
    }
  
    return false;
  }

export const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };