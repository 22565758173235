import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Table, TableHead, TableRow, TableCell, TableBody, TextField, IconButton, Paper, Snackbar, Alert } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MyDataContext } from '../context/CsvContext';

export default function FullTableEditor() {
  const [newRow, setNewRow] = useState({});
  const [newColumn, setNewColumn] = useState('');
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'info' });
  const { csvData, updateCsvData, handleBulkCreate } = useContext(MyDataContext);

  useEffect(() => {
    const savedData = localStorage.getItem('csvData');
    if (savedData) {
      updateCsvData(null, JSON.parse(savedData));
      localStorage.removeItem('csvData');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('csvData', JSON.stringify(csvData));
  }, [csvData]);
  

  const handleNewRowChange = (key, value) => {
    setNewRow({ ...newRow, [key]: value });
  };

  const addNewRow = () => {
    if (isRowValid(newRow)) {
      const updatedCsvData = [...csvData, newRow];
      updateCsvData(null, updatedCsvData);
      setNewRow({});
    } else {
      setAlert({ open: true, message: 'Please fill in all the fields.', severity: 'warning' });
    }
  };

  const addNewColumn = () => {
    if (newColumn) {
      const updatedData = csvData.map(row => ({ ...row, [newColumn]: '' }));
      updateCsvData(null, updatedData);
      setNewColumn('');
    }
  };

  const isRowValid = (row) => {
    return Object.values(row).every(value => value && value.trim() !== '');
  };

  const deleteRow = (index) => {
    const updatedCsvData = csvData.filter((_, idx) => idx !== index);
    updateCsvData(null, updatedCsvData);
  };

  const deleteColumn = (columnKey) => {
    const updatedData = csvData.map(row => {
      const newRow = { ...row };
      delete newRow[columnKey];
      return newRow;
    });
    updateCsvData(null, updatedData);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(csvData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateCsvData(null, items);
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <Paper sx={{ p: 4, overflowX: 'auto' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {csvData.length > 0 && Object.keys(csvData[0]).map((key, index) => (
                <TableCell key={index}>
                  {key}
                  <IconButton onClick={() => deleteColumn(key)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              ))}
              <TableCell>
                <TextField
                  placeholder="New Column"
                  value={newColumn}
                  onChange={(e) => setNewColumn(e.target.value)}
                  size="small"
                />
                <Button onClick={addNewColumn} color="primary">
                  Add Column
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="droppable">
            {(provided) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {csvData.map((row, index) => (
                  <Draggable key={index} draggableId={String(index)} index={index}>
                    {(provided) => (
                      <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {Object.keys(row).map((key, i) => (
                          <TableCell key={i}>
                            <TextField
                              value={row[key]}
                              onChange={(e) => updateCsvData(index, { ...row, [key]: e.target.value })}
                              size="small"
                            />
                          </TableCell>
                        ))}
                        <TableCell>
                          <IconButton onClick={() => deleteRow(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
          <TableRow>
            {csvData.length > 0 && Object.keys(csvData[0]).map((key, index) => (
              <TableCell key={index}>
                <TextField
                  placeholder={`New ${key}`}
                  value={newRow[key] || ''}
                  onChange={(e) => handleNewRowChange(key, e.target.value)}
                  size="small"
                />
              </TableCell>
            ))}
            <TableCell>
              <Button onClick={addNewRow} color="primary">
                Add Row
              </Button>
            </TableCell>
          </TableRow>
        </Table>
      </DragDropContext>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleBulkCreate(csvData)}
        sx={{ mt: 2 }}
      >
        Save Changes
      </Button>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}
