import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import { api } from "../../api/axios";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const headCells = [
  { id: "account_id", numeric: false, disablePadding: true, label: "Account ID" },
  { id: "transaction_id", numeric: false, disablePadding: false, label: "Transaction ID" },
  { id: "from_account", numeric: false, disablePadding: false, label: "From Account" },
  { id: "to_account", numeric: false, disablePadding: false, label: "To Account" },
  { id: "amount", numeric: false, disablePadding: false, label: "Amount" },
  { id: "comment", numeric: false, disablePadding: false, label: "Comment" },
  { id: "time", numeric: false, disablePadding: false, label: "Time" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function CustomersTableBody(props) {
  const { data, selected, setSelected, order, orderBy, page, rowsPerPage, dense } = props;

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <TableBody>
      {stableSort(data.transactions, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.transaction_id);
          const labelId = `enhanced-table-checkbox-${index}`;
          const IsActive = row.status === 0;

          return (
            <TableRow
              hover
              onClick={(event) => handleClick(event, row.transaction_id)}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.transaction_id}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </TableCell>
              <TableCell>{row.account_id}</TableCell>
              <TableCell>{row.transaction_id}</TableCell>
              <TableCell>{row.from_account}</TableCell>
              <TableCell>{row.to_account}</TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell>{row.comment}</TableCell>
              <TableCell>{moment.unix(row.time).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
              <TableCell>
                <div className={`flex gap-1 w-20 justify-center py-1 px-2 
                  ${IsActive ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-600'} 
                   rounded-lg `}>
                  <span>
                    <CircleIcon sx={{ fontSize: 10 }} />
                  </span>
                  <p>{IsActive ? "Success" : "Failed"}</p>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
}

export default function CustomersTable() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searchTxt, setSearchTxt] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [transactionsData, setTransactionsData] = useState({
    last_evaluated_key: null,
    transactions: [],
    total: 0,  // Add a total count to handle pagination
  });
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    account_id: '',
    transaction_status: '',
    start_time: '',
    end_time: '',
  });

  const fetchTransactions = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/nil-transactions', {
        withCredentials:true,
        params: {
          limit: rowsPerPage,
          last_evaluated_key: JSON.stringify(transactionsData.last_evaluated_key),
          ...filters,
        },
      });
      setTransactionsData((prevData) => ({
        last_evaluated_key: response.data.last_evaluated_key,
        transactions: [...prevData.transactions, ...response.data.transactions],
        total: response.data.total,  // Update the total count from the response
      }));
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setTransactionsData({
      last_evaluated_key: null,
      transactions: [],
      total: 0,
    });
    setPage(0);
  }, [filters]);

  useEffect(() => {
    fetchTransactions();
  }, [page, rowsPerPage, filters]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = transactionsData.transactions.map((n) => n.transaction_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSearchChange = (event) => {
    setSearchTxt(event.target.value);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: searchTxt,
    }));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Transactions
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete the selected transactions?
          </Typography>
          <div className="mt-4">
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                // Handle delete logic here
                handleClose();
              }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ ml: 2 }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" component="div">
              Nil Transactions
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchTxt}
                onChange={handleSearchChange}
                sx={{ mr: 2 }}
              />
              <Button variant="contained" onClick={handleSearch}>
                Search
              </Button>
            </Box>
            <Box sx={{ display: 'flex', mt: 2 }}>
              <TextField
                label="Account ID"
                variant="outlined"
                size="small"
                name="account_id"
                value={filters.account_id}
                onChange={handleFilterChange}
                sx={{ mr: 2 }}
              />
              <TextField
                label="Transaction Status"
                variant="outlined"
                size="small"
                name="transaction_status"
                value={filters.transaction_status}
                onChange={handleFilterChange}
                sx={{ mr: 2 }}
              />
              <TextField
                label="Start Time"
                variant="outlined"
                size="small"
                name="start_time"
                value={filters.start_time}
                onChange={handleFilterChange}
                sx={{ mr: 2 }}
              />
              <TextField
                label="End Time"
                variant="outlined"
                size="small"
                name="end_time"
                value={filters.end_time}
                onChange={handleFilterChange}
              />
            </Box>
          </Box>
          {isLoading ? (
            <div className="p-4">
              <CircularProgress />
            </div>
          ) : (
            <>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={transactionsData.transactions.length}
                  />
                  <CustomersTableBody
                    data={transactionsData}
                    selected={selected}
                    setSelected={setSelected}
                    order={order}
                    orderBy={orderBy}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    dense={dense}
                  />
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={transactionsData.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </>
  );
}
