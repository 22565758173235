import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Modal,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  DialogContentText,
  TextField,
  DialogActions,
  Snackbar,
  Alert,
  Grid,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Fab,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/material";
import Container from "@mui/material/Container";
import { api } from "../api/axios";
import CSVReader from "../components/csv";
import { Add, Title, Upload } from "@mui/icons-material";
import { useTable } from "react-table";
import { useAlert } from "../components/alert";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import AnimatedFab from "../components/animated_fab";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import EnhancedModal from "../components/modal";
import { MyDataContext } from "../context/CsvContext";
import { BeneficiaryContext } from "../context/BeneficiaryContext";

const useStyles = styled((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export function BeneficiaryList({ beneficiaries }) {
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, AlertSnackbar] = useAlert();

  const [fullPageView, setFullPageView] = useState(false);

  const { csvData, updateCsvData, handleBulkCreate } = useContext(MyDataContext);


  // const updateCsvData = (index, newData) => {
  //   setCsvData(currentData => 
  //     currentData.map((item, idx) => idx === index ? newData : item)
  //   );
  // };
  

  const getTourSteps = () => {
    const steps = [
            // Conditionally add this step if beneficiaries are loaded
            ...beneficiaries.length > 0 ? [{
              target: '.MuiTableContainer-root',
              content: 'This table lists all beneficiaries. You can hover over them to view details or transfer funds to them.',
              title: 'Beneficiary List',
            }] : [],


      // add another step for hovering over a row and suggest via joyride to click on the row
      {
        target: '.MuiTableRow-root',
        content: 'Hover over a row to view beneficiary details. Click on a row to view more details.',
        title: 'View Beneficiary',
        position: 'left'
      },
      {
        target: '.csv-reader',
        content: 'You can upload a CSV for beneficiaries here so it get added to nil system.',
        title: 'CSV Upload',
      },
    ];
    return steps;
  };
  

  const [runTour, setRunTour] = useState(false);
  const [tourCompleted, setTourCompleted] = useState(false);

  const startTour = () => {
    if (!tourCompleted) setRunTour(true);
  };

  useEffect(() => {
    startTour();
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      setTourCompleted(true);
    }
  };

  const handleFileLoaded = (data) => {
    updateCsvData(null, data); // Updating the entire dataset
    setIsModalOpen(true); // This should open the modal
  };
  
  


  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleVerify = () => {
    // Logic for verifying the beneficiary
    // You can make an API call to update the beneficiary's verification status
    console.log("Verifying beneficiary:", selectedBeneficiary);
  };

  const handleBeneficiaryClick = (beneficiary) => {
    navigate(`/beneficiary/${beneficiary.mobile_number}`);
  };
  


  return (
    <>

<EnhancedModal 
  isModalOpen={isModalOpen}
  handleClose={handleClose}
  csvData={csvData}
  handleBulkCreate={() => handleBulkCreate(csvData, showAlert, handleClose)}
  updateCsvData={updateCsvData}
/>

      <Card>
      <CSVReader onFileLoaded={handleFileLoaded} />
        <TableContainer component={Paper}>
          <Table className="beneficiaries-table">
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Account ID</TableCell>
                <TableCell>Mobile Number</TableCell>
                <TableCell>City</TableCell>
                {/* Add more table headers here */}
              </TableRow>
            </TableHead>
            <TableBody>
              {beneficiaries.map((beneficiary) => (
                <TableRow
                  key={beneficiary.full_name}
                  onClick={() => handleBeneficiaryClick(beneficiary)}
                >
                  <TableCell>{beneficiary.full_name}</TableCell>
                  <TableCell>{beneficiary.account_id}</TableCell>
                  <TableCell>{beneficiary.mobile_number}</TableCell>
                  <TableCell>{beneficiary.city}</TableCell>
                
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{selectedBeneficiary?.full_name} Details</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            Account ID: {selectedBeneficiary?.account_id}
          </Typography>
          <Typography variant="subtitle1">
            Birthday: {selectedBeneficiary?.birthday}
          </Typography>
          <Typography variant="subtitle1">
            City: {selectedBeneficiary?.city}
          </Typography>
          <Typography variant="subtitle1">
            Dependants: {selectedBeneficiary?.dependants}
          </Typography>
          <Typography variant="subtitle1">
            Income Last Year: {selectedBeneficiary?.income_last_year}
          </Typography>
          <Typography variant="subtitle1">
            Enroll SMEs Program:{" "}
            {selectedBeneficiary?.enroll_sm_es_program ? "Yes" : "No"}
          </Typography>
          <Typography variant="subtitle1">
            Is Verified: {selectedBeneficiary?.is_verified ? "Yes" : "No"}
          </Typography>
          <Typography variant="subtitle1">
            ID Type: {selectedBeneficiary?.id_type}
          </Typography>
          <Typography variant="subtitle1">
            ID Number: {selectedBeneficiary?.id_number}
          </Typography>
          <Typography variant="subtitle1">
            Mobile Number: {selectedBeneficiary?.mobile_number}
          </Typography>
          <Typography variant="subtitle1">
            Created At: {selectedBeneficiary?.created_at}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleVerify}
            disabled={selectedBeneficiary?.is_verified}
          >
            Verify
          </Button>
        </DialogContent>
      </Dialog>
      

      <Joyride
        steps={getTourSteps()}
        run={runTour}
        continuous
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{ options: { zIndex: 10000 } }}
        debug={true} // Enable debug mode
      />
<button onClick={startTour} disabled={tourCompleted}>Start Tour</button>
    </>
  );
}

export function BeneficiaryDetails({  match }) {
  const classes = useStyles();
  const { mobile_number } = useParams();
  const { beneficiaries, loading } = useContext(BeneficiaryContext);

  const beneficiary = beneficiaries.find(
    (beneficiary) => beneficiary.mobile_number === mobile_number
  );
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const handleVerify = () => {
    console.log("Verifying beneficiary:", beneficiary);
  };

  const handleNotesChange = (event) => {
    console.log("Notes:", event.target.value);
  };

  const handleTransferFunds = async (amount, currency, otp) => {
    console.log("Transferring funds:", beneficiary.account_id);
    const response = await api.post("/transfer", {
      amount: parseFloat(amount),
      to_account: beneficiary.account_id,
      from_account: "249_ACCT_1",
    }, {withCredentials:true});
    console.log("the response is: ", response)
    if (response.status === 200) {
      setAlertMsg("Funds transferred successfully.");
      setAlertSeverity("success");
    } else {
      setAlertMsg("Failed to transfer funds..");
      setAlertSeverity("error");
    }
    setAlertOpen(true);
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const fetchExchangeRate = async (currency) => {
    const response = await fetch(`/api/getExchangeRate?currency=${currency}`);
    const data = await response.json();
    return data.rate;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container component="main" maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Beneficiary Details
        </Typography>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h5" component="h2">
            {beneficiary.full_name} Details
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {Object.keys(beneficiary).map((key, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <strong>{key}</strong>
                    </TableCell>
                    <TableCell align="right">{beneficiary[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Transfer Funds
        </Typography>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="amount"
                  label="Amount"
                  name="amount"
                  autoComplete="amount"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel id="currency-label">Currency</InputLabel>
                  <Select
                    labelId="currency-label"
                    id="currency"
                    defaultValue="USD"
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="SDG">Sudanese Pound</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleClickOpen}
                >
                  Transfer
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">OTP Verification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            An OTP has been sent to your registered mobile number. Please enter
            the OTP below to verify.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="otp"
            label="OTP"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleTransferFunds(
                document.getElementById("amount").value,
                document.getElementById("currency").value,
                document.getElementById("otp").value
              )
            }
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alertSeverity}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </Container>
  );
}
