import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, CircularProgress } from '@mui/material';
import { AssignmentTurnedIn } from '@mui/icons-material';

export const AgentDialog = ({ open, handleClose, selectedAgent, handleUpdateAgent }) => {
  const [newInitialAmount, setNewInitialAmount] = useState('');
  const [updating, setUpdating] = useState(false);

  const handleUpdate = async () => {
    setUpdating(true);
    await handleUpdateAgent(selectedAgent.id, parseFloat(newInitialAmount));
    setUpdating(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Update Initial Amount</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update the initial amount for {selectedAgent && selectedAgent.name}.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="newInitialAmount"
          label="New Initial Amount"
          type="number"
          fullWidth
          value={newInitialAmount}
          onChange={(e) => setNewInitialAmount(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" disabled={updating}>
          {updating ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export const ConfirmDialog = ({ open, handleClose, title, content, onConfirm }) => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  

