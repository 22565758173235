import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Drawer } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChatIcon from '@mui/icons-material/Chat';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { AddIcCallRounded, ListRounded } from "@mui/icons-material";

const drawerWidth = 240;

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const list = [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      to: "/",
    },
    {
      text: "Customers",
      icon: <PeopleIcon />,
      to: "/users",
    },
    {
      text: "Transactions",
      icon: <AssignmentIcon />,
      to: "/transactions",
    },
    {
      text: "Tickets",
      icon: <ConfirmationNumberIcon />,
      to: "/tickets",
    },
    {
      text: "Chat",
      icon: <ChatIcon />,
      to: "/chat",
    },
    {
      text: "Reporting",
      icon: <BarChartIcon />,
      to: "/reports",
    },
    {
      text: "Add beneficiaries",
      icon: <AddIcCallRounded />,
      to: "/register",
    },
    {
      text: "List beneficiaries",
      icon: <ListRounded />,
      to: "/beneficiaries",
    },
    {
      text: "Nil Transactions",
      icon: <AssignmentIcon />,
      to: "/nil-transactions",
    },
  ];

  let activeStyle = {
    color: "#f9550e",
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {list.map((item, index) => (
            <NavLink
              className='list-link'
              style={({ isActive }) => isActive ? activeStyle : undefined}
              to={item.to}
              key={item.text}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon className="main-color">{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;