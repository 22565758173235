import React, { useState } from "react";
import { TextField, Checkbox, FormControlLabel, LinearProgress, Snackbar, Box, Stepper, StepLabel, Step, CircularProgress, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Button } from "@mui/material";
import { api } from "../api/axios";
import MuiAlert from '@mui/material/Alert';
import { Formik, Field, Form, ErrorMessage } from 'formik';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const steps = [
  {
    title: "Personal Details",
    fields: [
      { label: "Full Name", key: "full_name", type: "text" },
      { label: "Birthday", key: "birthday", type: "date" },
      { label: "City", key: "city", type: "text" },
      { label: "Mobile number", key: "mobile_number", type: "text" },
    ],
  },
  {
    title: "Financial Details",
    fields: [
      { label: "Dependants", key: "dependants", type: "number" },
      { label: "Income Last Year", key: "income_last_year", type: "number" },
      {
        label: "Interested in enrolling in 249 SMEs program?",
        key: "enroll_smes_program",
        type: "checkbox",
      },
    ],
  },
  {
    title: "Confirmation",
    fields: [
      {
        label: "Confirm your registration",
        key: "confirm",
        type: "checkbox",
      },
    ],
  },
];

const initialValues = {
  full_name: "",
  birthday: "",
  city: "",
  mobile_number: "",
  dependants: 0,
  income_last_year: 0,
  enroll_smes_program: false,
  confirm: false,
};

const RegistrationForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(initialValues);
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: '',
    message: ''
  });

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleNext = () => {
    if (isStepComplete()) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
  const handleConfirm = () => {
    formData.dependants = parseInt(formData.dependants);
    formData.income_last_year = parseFloat(formData.income_last_year);
   
      api
        .post("/create", formData)
        .then((response) => {
          setSnackbar({
            open: true,
            severity: 'success',
            message: 'Registration successful'
          });
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            severity: 'error',
            message: error.response.data.message
          });
        });
    
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prevSnackbar => ({ ...prevSnackbar, open: false }));
  };

  const [error, setError] = useState({});
  const isStepComplete = () => {
    let errors = {};
    let complete = true;
    steps[activeStep].fields.forEach((field) => {
      if (!Boolean(formData[field.key])) {
        errors[field.key] = true;
        complete = false;
      }
    });
    setError(errors);
    return complete;
  };

  return (
    <Box className="max-w-screen-lg mx-auto bg-white p-5 rounded-md shadow-sm transition-all duration-200">
      <Box className="text-center p-3">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step) => (
            <Step key={step.title}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <LinearProgress variant="determinate" value={(activeStep / (steps.length - 1)) * 100} />
      </Box>

      {activeStep < steps.length - 1 ? (
        <Box className="mb-5 flex flex-col space-y-4 mt-16 pt-20"> 
          {steps[activeStep].fields.map((field) => (
            <Box key={field.key} className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label htmlFor={field.key} className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                {field.label}
              </label>
              <Box className="mt-2 sm:col-span-2 sm:mt-0">
                {field.type === "checkbox" ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData[field.key]}
                        onChange={(e) => handleChange(field.key, e.target.checked)}
                      />
                    }
                    error={error[field.key]}
                  />
                ) : (
                  <TextField
                    type={field.type}
                    value={formData[field.key]}
                    onChange={(e) => handleChange(field.key, e.target.value)}
                    fullWidth
                    error={!!error[field.key]}
                    helperText={error[field.key] ? "This field is required" : ""}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {Object.entries(formData).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell align="right">{String(value)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box className="mt-6 flex items-center justify-end gap-x-6 fixed inset-x-0 bottom-0 p-4 bg-white shadow-lg">
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Back
        </Button>

        {activeStep !== steps.length - 1 ? (
          <Button
          onClick={handleNext}
          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
      >
          Next
      </Button>
        ) : (
          <Button
            onClick={handleConfirm}
            className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
          >
            Confirm
          </Button>
        )}
      </Box>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default RegistrationForm;