// AdminDonorsPortfolioSection.jsx
import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { supabase } from '../supabase';


const AdminDonorsPortfolioSection = () => {
  const [donors, setDonors] = useState([]);
  const [editingDonor, setEditingDonor] = useState(null); // null or donor object
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    fetchDonors();
  }, []);

  const fetchDonors = async () => {
    let { data, error } = await supabase
      .from('donors')
      .select('*');

    if (error) console.log('error', error);
    else setDonors(data);
  };
  const handleAddClick = () => {
    setEditingDonor({ name: '', project: '', impact: '' });
    setDialogOpen(true);
  };

  const handleEditClick = (donor) => {
    setEditingDonor(donor);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setEditingDonor(null);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (editingDonor) {
      // Determine if it's an add or update action based on the presence of 'id'
      const action = editingDonor.id ? 'update' : 'insert';
      const { data, error } = await supabase
        .from('donors')
        [action](editingDonor);

      if (error) {
        console.error('Error:', error);
      } else {
        setDonors(action === 'update'
          ? donors.map((d) => d.id === data[0].id ? data[0] : d)
          : [...donors, ...data]);
        handleDialogClose();
      }
    }
  };

  const handleSave = async () => {
    if (editingDonor) {
      const { data, error } = editingDonor.id
        ? await supabase.from('donors').update(editingDonor).match({ id: editingDonor.id })
        : await supabase.from('donors').insert([editingDonor]);

      if (error) console.log('error', error);
      else {
        setDonors([...donors, ...data]);
        handleDialogClose();
      }
    }
  };

  const handleChange = (e, field) => {
    setEditingDonor({ ...editingDonor, [field]: e.target.value });
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add Donor
      </Button>
      <Grid container spacing={2}>
        {/* Existing donors list */}
        {donors.map((donor, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6">{donor.name}</Typography>
                <Typography variant="subtitle1">Project: {donor.project}</Typography>
                <Typography variant="body1">Impact: {donor.impact}</Typography>
                <Button onClick={() => handleEditClick(donor)}>Edit</Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{editingDonor ? 'Edit Donor' : 'Add Donor'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="dense"
            value={editingDonor?.name}
            onChange={(e) => handleChange(e, 'name')}
          />
          <TextField
            label="Project"
            fullWidth
            margin="dense"
            value={editingDonor?.project}
            onChange={(e) => handleChange(e, 'project')}
          />
          <TextField
            label="Impact"
            fullWidth
            margin="dense"
            value={editingDonor?.impact}
            onChange={(e) => handleChange(e, 'impact')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminDonorsPortfolioSection;
