import { useState, useContext, useEffect } from "react";
import { Stack } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, useLocation } from "react-router-dom";
import { Header, Loader, ProtectedRoute, Sidebar } from "./components";
import { ClientsProfile, Home, Login, Profile, Reports, Transactions, Customers } from "./pages";
import { authContext } from "./context/AuthContext";
import TransactionInfo from "./pages/TransactionInfo";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import { GoogleOAuthProvider } from "@react-oauth/google";
import RegistrationForm from "./pages/RegistrationForm";
import { BeneficiaryDetails, BeneficiaryList } from "./pages/Beneficiaries";
import { api } from "./api/axios";
import config from "./config.json"

import { IntercomProvider } from "react-use-intercom";
import { MyDataProvider } from "./context/CsvContext";
import FullTableEditor from "./components/full-modal";
import AdminDonorsPortfolioSection from "./components/admin-donors";
import { BeneficiaryContextProvider } from "./context/BeneficiaryContext";
import CustomersTable from "./components/tables/NilTransactions";
import AgentDashboard from "./pages/Agents";
import BeneficiaryDataTable from "./components/tables/BeneficiaryTable";
import JournalDashboard from "./components/Journal";
import Summary from "./components/GrantsSummary";

function App() {  
  const { pathname } = useLocation();  
  const { isAuthenticated, loading } = useContext(authContext);  
  const [mobileOpen, setMobileOpen] = useState(false);  
  const [beneficiaries, setBeneficiaries] = useState([]); // Define beneficiaries state variable  
  const INTERCOM_APP_ID = 's4t28htx';
  
  const handleDrawerToggle = () => {  
    setMobileOpen(!mobileOpen);  
  };  
  
  const theme = createTheme({  
    palette: {  
      primary: {  
        main: '#000080',  
      },  
    },  
  });  
  
  useEffect(() => {  
    // Fetch the beneficiary data from the server using axios or fetch API  
    api.get('/beneficiaries', { withCredentials: true })  
      .then(response => setBeneficiaries(response.data))  
      .catch(error => console.error(error));  
  }, []);  
  
  return (
    <MyDataProvider>
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
    <GoogleOAuthProvider clientId="569761151122-tijop2lc5g81g40jj62a85jkiccoop89.apps.googleusercontent.com" >  
      <ThemeProvider theme={theme}>  
        <div className="App">  
          {pathname !== "/login" && <Header toggleFunc={handleDrawerToggle} />}  
          <Stack direction="row" spacing={1}>  
            {pathname !== "/login" && (  
              <Sidebar open={mobileOpen} close={handleDrawerToggle} />  
            )}  
            <Routes>
                
              <Route path="/login" element={<Login />} />
          
              <Route element={<ProtectedRoute path="/" />}>
              <Route path="/" element={<Summary />} /> 
              </Route>
              <Route path="/users" element={<Customers />} />  
              <Route path="/register" element={<section className="transactions h-full flex flex-col gap-5">  
                <div className="w-fit">  
                  <h1 className="relative font-bold text-2xl text-gray-800">Add beneficiaries  
                  <span className="absolute bottom-0 left-0 h-[3px] rounded-sm w-1/2 bg-orange-500"></span>  
                  </h1>  
                </div>  
                <div className="table-container">  
                  <RegistrationForm />  
                </div>  
              </section>} />  
              <Route path="/beneficiaries" element={ 
                <BeneficiaryContextProvider>
                <section className="transactions h-full flex flex-col gap-5">  
                  <div className="w-fit">  
                    <h1 className="relative font-bold text-2xl text-gray-800">List beneficiaries  
                    <span className="absolute bottom-0 left-0 h-[3px] rounded-sm w-1/2 bg-orange-500"></span>  
                    </h1>  
                  </div>  
                  <div className="table-container">  
                    <BeneficiaryDataTable beneficiaries={beneficiaries} />  
                  </div>  
                </section>
                
                </BeneficiaryContextProvider>}
                
                />  
              <Route  
                path="/beneficiary/:mobile_number"  
                element={
                  <BeneficiaryContextProvider>
                  <section className="transactions h-full flex flex-col gap-5">  
                    <div className="w-fit">  
                      <h1 className="relative font-bold text-2xl text-gray-800">List beneficiaries  
                      <span className="absolute bottom-0 left-0 h-[3px] rounded-sm w-1/2 bg-orange-500"></span>  
                      </h1>  
                    </div>  
                    <div className="table-container">  
                      <BeneficiaryDetails beneficiaries={beneficiaries} />  
                    </div>  
                  </section>
                  </BeneficiaryContextProvider> 
                } 
              /> 
               <Route path="/full-table-editor" element={<FullTableEditor />} />
              <Route path="/users/:id" element={<ClientsProfile />} />  
              <Route path="/transactions" element={<Transactions />} />  
              <Route path="/transactions/:id" element={<TransactionInfo />} />  
              <Route path="/nil-transactions" element={<CustomersTable />} />
              <Route path="/reports" element={<Reports />} />  
              <Route path="/profile" element={<Profile />} />  
              <Route path="/admin/donors" element={<AdminDonorsPortfolioSection />} />
              <Route path="/agents" element={<AgentDashboard/>}/>
            </Routes>  
            {/* {pathname !== "/login" && (  
              <Livechat/>  
            )} */}  
          </Stack>  
  
        </div>  
        <ToastContainer />  
  
      </ThemeProvider>  
    </GoogleOAuthProvider> 
    </IntercomProvider>
    </MyDataProvider>
  )  
}  
  
export default App;  
