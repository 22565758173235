import React, { createContext, useState } from 'react';
import { api } from '../api/axios';

// Create the context
export const MyDataContext = createContext();

export const MyDataProvider = ({ children }) => {
  // State that you want to share across components
  const [csvData, setCsvData] = useState([]);
  
  // Update function for csvData
  const updateCsvData = (index, newData) => {
    if (index != null) {
      // Update a specific row
      setCsvData(currentData => 
        currentData.map((item, idx) => idx === index ? newData : item)
      );
    } else {
      // Update all data
      setCsvData(newData);
    }
  };

  const handleBulkCreate = async (data, showAlert, handleClose) => {
    console.log("Uploading data:", data);

    const formattedDataRow = (row) => {
      return {
        ...row,
        account_id: row.account_id?.toString() || "",
        full_name: row.full_name?.toString() || "",
        birthday: row.birthday?.toString() || "",
        city: row.city?.toString() || "",
        dependants: parseInt(row.dependants, 10) || 0,
        income_last_year: parseFloat(row.income_last_year) || 0.0,
        enroll_smes_program: row.enroll_smes_program === "Yes",
        is_verified: row.is_verified === "Yes",
        id_type: row.id_type?.toString() || "",
        mobile_number: row.mobile_number?.toString() || "",
        id_number: row.id_number?.toString() || "",
        pic_id_card: row.pic_id_card?.toString() || "",
        amount: parseFloat(row.amount) || 0.0,
        currency: row.currency?.toString() || "",
        confirm: row.confirm === "Yes",
        external_auth: row.external_auth === "Yes",
        password: row.password?.toString() || "",
        created_at: row.created_at?.toString() || new Date().toISOString(), // Assuming you want the current date-time as default
      }
    }



    try {
      const formattedData = data.map(row => formattedDataRow(row));
      const response = await api.post("/create-accounts", formattedData, {withCredentials:true});
      if (response.ok) {
        showAlert("Funds transferred successfully.", "success");
      } else {
        showAlert("Failed to transfer funds.", "error");
      }
    } catch (error) {
      console.error("Error during bulk creation:", error);
      showAlert("An error occurred.", "error");
    } finally {
      if (handleClose) handleClose();
    }
  };

  // The value that will be accessible to all components
  const contextValue = {
    csvData,
    updateCsvData,
    handleBulkCreate,
  };

  return (
    <MyDataContext.Provider value={contextValue}>
      {children}
    </MyDataContext.Provider>
  );
};
