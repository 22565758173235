import React, { useEffect, useState } from 'react';
import {
  Container, Box, Typography, Table, TableBody, TableCell, TableHead, TableRow,
  TextField, Button, CircularProgress, Snackbar, Alert, Grid, Tooltip, IconButton, TablePagination, Paper
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getAgents, addAgent, updateAgent, deleteAgent } from '../api/axios';
import { AgentDialog, ConfirmDialog } from '../components/dialog';

const AgentDashboard = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchAgents(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const fetchAgents = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await getAgents(page + 1, pageSize); // API expects 1-based page index
      setAgents(response.data || []); // Ensure agents is always an array
      setTotal(response.total || 0);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching agents', severity: 'error' });
      setAgents([]); // Ensure agents is an empty array in case of error
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = (agent) => {
    setSelectedAgent(agent);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAgent(null);
  };

  const handleDeleteOpen = (agent) => {
    setSelectedAgent(agent);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedAgent(null);
  };

  const handleAddAgent = async (values) => {
    try {
      await addAgent(values);
      fetchAgents(page, rowsPerPage);
      formik.resetForm();
      setSnackbar({ open: true, message: 'Agent added successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error adding agent', severity: 'error' });
    }
  };

  const handleUpdateAgent = async (id, newInitialAmount) => {
    try {
      await updateAgent(id, { initialAmount: newInitialAmount });
      fetchAgents(page, rowsPerPage);
      handleClose();
      setSnackbar({ open: true, message: 'Agent updated successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error updating agent', severity: 'error' });
    }
  };

  const handleDeleteAgent = async (id) => {
    try {
      await deleteAgent(id);
      fetchAgents(page, rowsPerPage);
      handleDeleteClose();
      setSnackbar({ open: true, message: 'Agent deleted successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting agent', severity: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      currentAmount: '',
      initialAmount: '',
      mobile: '',
      location: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      currentAmount: Yup.number().required('Required').positive('Must be positive'),
      initialAmount: Yup.number().required('Required').positive('Must be positive'),
      mobile: Yup.string().required('Required'),
      location: Yup.string().required('Required'),
    }),
    onSubmit: handleAddAgent,
  });

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredAgents = agents.filter((agent) => {
    return agent.name.toLowerCase().includes(search.toLowerCase()) ||
           agent.mobile.includes(search) ||
           agent.location.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Agent Dashboard</Typography>
      <Box sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Search"
              value={search}
              onChange={handleSearchChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              id="name"
              name="name"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              id="currentAmount"
              name="currentAmount"
              label="Current Amount"
              type="number"
              value={formik.values.currentAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.currentAmount && Boolean(formik.errors.currentAmount)}
              helperText={formik.touched.currentAmount && formik.errors.currentAmount}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              id="initialAmount"
              name="initialAmount"
              label="Initial Amount"
              type="number"
              value={formik.values.initialAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.initialAmount && Boolean(formik.errors.initialAmount)}
              helperText={formik.touched.initialAmount && formik.errors.initialAmount}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              id="mobile"
              name="mobile"
              label="Mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="location"
              name="location"
              label="Location"
              value={formik.values.location}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.location && Boolean(formik.errors.location)}
              helperText={formik.touched.location && formik.errors.location}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              startIcon={<Add />}
            >
              Add Agent
            </Button>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100px">
          <CircularProgress />
        </Box>
      ) : filteredAgents.length === 0 ? (
        <Typography variant="h6" align="center">No agents available. Please add a new agent.</Typography>
      ) : (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Current Amount</TableCell>
                <TableCell>Initial Amount</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAgents.map((agent) => (
                <TableRow key={agent.id}>
                  <TableCell>{agent.name}</TableCell>
                  <TableCell>{agent.currentAmount}</TableCell>
                  <TableCell>{agent.initialAmount}</TableCell>
                  <TableCell>{agent.mobile}</TableCell>
                  <TableCell>{agent.location}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleClickOpen(agent)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleDeleteOpen(agent)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}  // Total count from the backend
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      <AgentDialog
        open={open}
        handleClose={handleClose}
        selectedAgent={selectedAgent}
        handleUpdateAgent={handleUpdateAgent}
      />

      <ConfirmDialog
        open={deleteOpen}
        handleClose={handleDeleteClose}
        title="Delete Agent"
        content={`Are you sure you want to delete ${selectedAgent?.name}?`}
        onConfirm={() => handleDeleteAgent(selectedAgent?.id)}
      />

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AgentDashboard;
