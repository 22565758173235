import { Navigate, Outlet } from "react-router-dom";


import React, { useContext } from 'react';

import { authContext } from '../context/AuthContext';
import Loader from "./Loader";

const ProtectedRoute = () => {
  const { isAuthenticated, loading } = useContext(authContext);

  if (loading) return <Loader />; // Assuming Loader is your loading component

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;