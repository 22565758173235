import axios from "axios";
let config = require('../config.json');

export const api = axios.create({
    // check if hostname is localhost, then use localhost server endpoint
    withCredentials:true,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    baseURL: window.location.hostname.startsWith("http://localhost")? config.local_url : config.prod_url,
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem("auth");
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


export const getUsers = async () => {
    const response = await api.get('/users', { withCredentials: true });
    return response.data;
}

export const getUrl = () => {
    console.log("the baseurl is ", window.location.hostname)
    if (window.location.hostname.startsWith("http://localhost")) {
        return config.local_url;
    } else {
        return config.prod_url;
    }
}

export const getTrans = async () => {
    const response = await api.get('/transactions', { withCredentials: true });
    return response.data;
}


// Add this function to your existing api.js or equivalent file
export const addAdmin = async (adminData) => {
  const response = await api.post('/admin/create-user', adminData, { withCredentials: true });
  return response.data;
}


export const getAgents = async (page, pageSize) => {
  const response = await api.get('/agents/', {
      params: { page, pageSize },
      withCredentials: true,
      

  });
  return response.data;
}


export const addAgent = async (agent) => {
    const response = await api.post('/agents/', agent, { withCredentials: true });
    return response.data;
}

export const updateAgent = async (id, updatedData) => {
    const response = await api.put(`/agents/${id}`, updatedData, { withCredentials: true });
    return response.data;
}

export const deleteAgent = async (id) => {
  const response = await api.delete(`/agents/${id}`, { withCredentials: true });
  return response.data;
}

export const getGrants = async () => {
  const response = await api.get('/agents/grants', { withCredentials: true });
  return response.data;
}