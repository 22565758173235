import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography, Box, Paper, useTheme, IconButton, Tooltip } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Import Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import { Brightness4, Brightness7 } from '@mui/icons-material';

// Register components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend
);

const columns = [
  { field: 'date', headerName: 'Date', width: 150 },
  { field: 'description', headerName: 'Description', width: 300 },
  { field: 'paymentRequestNumber', headerName: 'Payment Request Number', width: 250 },
  { field: 'dr', headerName: 'Debit (Dr)', width: 150 },
  { field: 'cr', headerName: 'Credit (Cr)', width: 150 },
  { field: 'balance', headerName: 'Balance', width: 200 },
];

const rows = [
    { id: 1, date: '21-May-24', description: 'BOKI EUR A/C – Gaith Fund', paymentRequestNumber: '', dr: '€ 297,261', cr: '', balance: '' },
    { id: 2, date: '21-May-24', description: 'Gaith Fund Disbursement A/C', paymentRequestNumber: '', dr: '', cr: 'SDG 400,481,909.64', balance: '' },
    { id: 3, date: '', description: 'Notes: used Bank of Khartoum rate date 21 May 2024 1EUR=1347.24', paymentRequestNumber: '', dr: '', cr: '', balance: '' },
    { id: 4, date: '', description: 'Opening Balance', paymentRequestNumber: '', dr: '', cr: 'SDG 400,481,909.64', balance: 'SDG 400,481,909.64' },
    { id: 5, date: '24-Jun-24', description: 'Being Payment of fund disbursment noRef No.: IC/6.6/638/31438', paymentRequestNumber: 'PR/Gaith/2024/31438/FD01', dr: 'SDG 37,061,600.00', cr: '', balance: 'SDG 363,420,309.64' },
    { id: 6, date: '2-Jul-24', description: 'Being Payment of fund disbursment noRef No.: IC/2.8/1101/82509', paymentRequestNumber: 'PR/Gaith/2024/82509/FD01', dr: 'SDG 12,294,912.00', cr: '', balance: 'SDG 351,125,397.64' },
    { id: 7, date: '2-Jul-24', description: 'Being Payment of fund disbursment noRef No.: IC/6.8/1014/30747', paymentRequestNumber: 'PR/Gaith/2024/50683/FD01', dr: 'SDG 11,133,000.00', cr: '', balance: 'SDG 339,992,397.64' },
    { id: 8, date: '11-Jul-24', description: 'Being Payment of fund disbursment noRef No.: IC/5.6/242/61535', paymentRequestNumber: 'PR/Gaith/2024/61535/FD01', dr: 'SDG 35,500,000.00', cr: '', balance: 'SDG 304,492,397.64' },
    { id: 9, date: '18-Jul-24', description: 'Being Payment of fund disbursment noRef No.: IC/4.6/848/95342', paymentRequestNumber: 'PR/Gaith/2024/95342/FD01', dr: 'SDG 12,135,019.00', cr: '', balance: 'SDG 292,357,378.64' },
    { id: 10, date: '25-Jul-24', description: 'Being Payment of fund disbursment noRef No.: IC/5.8/124/30279', paymentRequestNumber: 'PR/Gaith/2024/30279/FD01', dr: 'SDG 2,427,000.00', cr: '', balance: 'SDG 289,930,378.64' },
    { id: 11, date: '31-Jul-24', description: 'Being Payment of fund disbursment noRef No.: IC/1.6/1014/30747', paymentRequestNumber: 'PR/Gaith/2024/30747/FD01', dr: 'SDG 40,417,000.00', cr: '', balance: 'SDG 249,513,378.64' },
    { id: 12, date: '31-Jul-24', description: 'Being Payment of fund disbursment noRef No.: IC/4.8/2228/54415', paymentRequestNumber: 'PR/Gaith/2024/54415/FD01', dr: 'SDG 13,170,000.00', cr: '', balance: 'SDG 236,343,378.64' },
    { id: 13, date: '31-Jul-24', description: 'Being Payment of fund disbursment noRef No.: IC/1.8/16/98555', paymentRequestNumber: 'PR/Gaith/2024/98555/FD01', dr: 'SDG 13,287,087.00', cr: '', balance: 'SDG 223,056,291.64' },
    { id: 14, date: '2-Aug-24', description: 'Being Payment of fund disbursment noRef No.: IC/2.6/283/63954', paymentRequestNumber: 'PR/Gaith/2024/63954/FD01', dr: 'SDG 43,033,540.00', cr: '', balance: 'SDG 180,022,751.64' },
    { id: 15, date: '8-Aug-24', description: 'Being Payment of fund disbursment noRef No.: IC/3.6/135/88533', paymentRequestNumber: 'PR/Gaith/2024/88533/FD01', dr: 'SDG 21,492,323.00', cr: '', balance: 'SDG 158,530,428.64' },
    { id: 16, date: '8-Aug-24', description: 'Being Payment of fund disbursment as agreed contract', paymentRequestNumber: 'PR/Gaith/2024/FD10', dr: 'SDG 22,500,000.00', cr: '', balance: 'SDG 136,030,428.64' },
    { id: 17, date: '', description: 'Balance', paymentRequestNumber: '', dr: 'SDG 264,451,481.00', cr: 'SDG 400,481,909.64', balance: '-SDG 136,030,428.64' },
  ];

const balanceData = rows.map(row => ({
  date: row.date,
  balance: parseFloat(row.balance.replace(/,/g, ''))
}));

const dates = balanceData.map(data => data.date);
const balances = balanceData.map(data => data.balance);

const chartData = {
  labels: dates,
  datasets: [
    {
      label: 'Balance Over Time (SDG)',
      data: balances,
      fill: false,
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: 'rgba(75,192,192,1)',
      tension: 0.1,
    },
  ],
};

const chartOptions = {
  scales: {
    y: {
      beginAtZero: false,
      ticks: {
        callback: function(value) {
          return value.toLocaleString();
        }
      },
      title: {
        display: true,
        text: 'Balance (SDG)',
      }
    },
    x: {
      title: {
        display: true,
        text: 'Date',
      }
    }
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          label += context.parsed.y.toLocaleString();
          return label;
        }
      }
    }
  }
};

export default function JournalDashboard() {
  const [darkMode, setDarkMode] = useState(false);
  const theme = useTheme();

  const customTheme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <Paper sx={{ padding: 3, borderRadius: 2 }}>
        <Container>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
            <Typography variant="h4" component="h1" gutterBottom>
              Gaith Fund Disbursement Journal
            </Typography>
            
          </Box>

          <Box sx={{ height: 500, width: '100%', marginBottom: 4 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection
              disableSelectionOnClick
              sx={{
                backgroundColor: theme.palette.background.paper,
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.common.black,
                },
                '& .MuiDataGrid-cell': {
                  fontSize: '1rem',
                },
              }}
            />
          </Box>

          <Box sx={{ marginTop: 4 }}>
            <Typography variant="h6" gutterBottom>
              Balance Over Time
            </Typography>
            <Line data={chartData} options={chartOptions} />
          </Box>
        </Container>
      </Paper>
    </ThemeProvider>
  );
}
