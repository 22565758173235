import { useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import { Apexchart, Barchart, ClientTable } from "../components";
import { useQuery } from "react-query";
import { addAdmin, getUsers } from "../api/axios";

const Profile = () => {
  const [disabled, setDisabled] = useState(true);
  const [newAdmin, setNewAdmin] = useState({
    email: "",
    tenant_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleNewAdminChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddAdmin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    try {
      const response = await addAdmin(newAdmin);
      setSuccessMessage("Admin added successfully!");
      setNewAdmin({
        email: "",
        tenant_id: "",
      });
    } catch (error) {
      setErrorMessage("Error adding admin. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="profile">
      <Container>
        <Box sx={{ textAlign: "center", margin: "auto", mb: 5 }}>
          <Avatar
            alt="Profile Avatar"
            src={require("../assets/images/avatar.jpg")}
            sx={{ width: 120, height: 120, margin: "auto", mb: 1 }}
          />
          <Typography variant="h5" component="h1">
            {JSON.parse(localStorage.getItem("user_profile")).name}
          </Typography>
        </Box>

        <Box mb={3} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDisabled(false)}
          >
            Edit info
          </Button>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              fullWidth
              variant="outlined"
              value="Mohamed Ahmed"
              disabled={disabled}
              style={{ marginBottom: "20px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Username"
              fullWidth
              variant="outlined"
              value="username"
              disabled={disabled}
              style={{ marginBottom: "20px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" style={{ marginBottom: "20px" }}>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                value="admin"
                disabled={disabled}
                label="Role"
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="superagent">Super agent</MenuItem>
                <MenuItem value="agent">Agent</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Location"
              fullWidth
              variant="outlined"
              value="Omdurman"
              disabled={disabled}
              style={{ marginBottom: "20px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              fullWidth
              variant="outlined"
              value="email@mail.com"
              disabled={disabled}
              style={{ marginBottom: "20px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              fullWidth
              variant="outlined"
              value="+249123456789"
              disabled={disabled}
              style={{ marginBottom: "20px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" style={{ marginBottom: "20px" }}>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                value="active"
                disabled={disabled}
                label="Status"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="suspend">Suspend</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              style={{
                marginBottom: "20px",
                padding: "15px 0",
                background: "#f9550e",
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>

        <Container>
          <Typography variant="h6" component="h2" gutterBottom>
            Add new admin:
          </Typography>
          <form onSubmit={handleAddAdmin}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  fullWidth
                  variant="outlined"
                  value={newAdmin.email}
                  onChange={handleNewAdminChange}
                  required
                  style={{ marginBottom: "20px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Tenant ID"
                  name="tenant_id"
                  fullWidth
                  variant="outlined"
                  value={newAdmin.tenant_id}
                  onChange={handleNewAdminChange}
                  required
                  style={{ marginBottom: "20px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={loading}
                  style={{
                    marginBottom: "20px",
                    padding: "15px 0",
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : "Add Admin"}
                </Button>
              </Grid>
            </Grid>
          </form>
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Container>
      </Container>
    </section>
  );
};

export default Profile;
