import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useQuery } from "react-query";
import {
  Button,
  Modal,
} from "@mui/material";
import { Stack } from "@mui/system";
import { api } from "../../../api/axios";
import Loader from "../../Loader";
import EnhancedTableHead from "../sharedComponents/EnhancedTableHead";
import TransactionsTableBody from "./TransactionsTableBody";
import { toast } from 'react-toastify';
import SearchBar from "../../filters/SearchBar";
import FiltersSection from "./FiltersSection";
import useDebounce from "../../../hooks/useDebounce";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "Pan",
    numeric: false,
    disablePadding: false,
    label: "Pan",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "Currency",
    numeric: false,
    disablePadding: false,
    label: "Currency",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "UUID",
    numeric: false,
    disablePadding: false,
    label: "Transaction ID",
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: false,
    label: "View",
  },
];

export default function EnhancedTable() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState(null);
  const [value2, setValue2] = React.useState(null);
  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [amount, setAmount] = React.useState("");
  const [transactionType, setTransactionType] = React.useState("");
  const [expDate, setExpDate] = React.useState('');
  const [pan, setPan] = React.useState('');
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  //using the debounce hook to prevent immediate api request on users input.
  const debouncedPAN = useDebounce(pan, 500);
  const debouncedAmount = useDebounce(amount, 500);

  // Fetching transactions data
  const getTrans = async () => {
    const response = await api.get(
      `/transactions?perPage=${rowsPerPage}&page=${page}&tran_amount=${debouncedAmount}&from=${fromDate}&to=${toDate}&pan=${debouncedPAN}&exp_date=${expDate}&type=${transactionType}`,
      { withCredentials: true }
    );
    return response.data;
  };

  const {
    isLoading,
    isError,
    error,
    data: transactionsData,
  } = useQuery(["getTrans", page, rowsPerPage, debouncedAmount, fromDate, toDate, debouncedPAN, expDate, transactionType], getTrans);


  useEffect(() => {
    fromDate&&console.log(fromDate);
    toDate&&console.log(toDate);
  }, [fromDate , toDate])
  
  useEffect(() => {
    if (error) {
      const customId = "custom-id-error"
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: customId,
      });
    }
  }, [error]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = transactionsData.data.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete a transaction log
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 1 }}>
            are you sure you want to delete:
          </Typography>
          {selected.map(({ name }) => (
            <Typography key={name}>- {name}</Typography>
          ))}
          <div className="mt-2" style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              disableElevation
              color="error"
            // onClick={() => deleteItem()}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              disableElevation
              style={{ margin: "0 10px" }}
              onClick={handleClose}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        alignItems={{ xs: "start", lg: "center" }}
        justifyContent="space-between"
        spacing={1}
        mb={1}
      >
        <FiltersSection setTransactionType={setTransactionType} setExpDate={setExpDate} setAmount={setAmount} setFromDate={setFromDate} setToDate={setToDate}>
          <SearchBar searchTxt={pan} setSearchTxt={setPan} placeholder={'Search by PAN'} />
        </FiltersSection>

      </Stack>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {isLoading ? <Loader /> : (
            <>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    headCells={headCells}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={transactionsData?.total_items || 0}
                  />
                  <TransactionsTableBody
                    transactionsData={transactionsData}
                    selected={selected}
                    setSelected={setSelected}
                    order={order}
                    orderBy={orderBy}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    dense={dense}
                  />
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={transactionsData?.total_items || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </>
  );
}
