import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { api } from "../api/axios";
import {
  Apexchart,
  Barchart,
  UserTable,
  UsersTable,
  Loader,
} from "../components";

const ClientsProfile = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    console.log("the account id to be sent to is: ", id);
    try {
      // Make API request to /api/transfer with amount and user_id
      const response = await api.post("/transfer", {
        amount: parseFloat(amount),
        to_account: id, // why this account is empty
        from_account: "249_ACCT_1"
      }, {withCredentials:true});
      // Show success message
      setSuccessMessage("Transfer successful");
    } catch (error) {
      // Show error message
      setErrorMessage(error.response.data.message);
    }
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const getUser = async () => {
    const response = await api.get(`/users/${id}`, {withCredentials:true});
    return response.data;
  };

  const data = {
    user: {
      name: "Ahmed",
      PAN: "9244",
      ID: "79",
      mobile: "0922092381",
      responseStatus: "nice",
    },
  };

  return (
    <section className="clients-profile">
      <Box
        sx={{
          textAlign: "center",
          mb: 2,
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src={require("../assets/images/avatar.jpg")}
          sx={{ width: 120, height: 120, margin: "auto", mb: 1 }}
        />
        <Typography variant="h5" component="h1">
          Ahmed Mohamed Ali
        </Typography>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send Money</DialogTitle>
        <DialogContent>
          <DialogContentText>
            How much funds do you intend to transfer?
          </DialogContentText>
          <Button
            variant="outlined"
            onClick={() => setAmount(50000)}
            sx={{ mx: 1, my: 1 }}
          >
            $50,000
          </Button>
          <Button
            variant="outlined"
            onClick={() => setAmount(100000)}
            sx={{ mx: 1, my: 1 }}
          >
            $100,000
          </Button>
          <Button
            variant="outlined"
            onClick={() => setAmount(40000)}
            sx={{ mx: 1, my: 1 }}
          >
            $40,000
          </Button>
          <input
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ mx: 1, my: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel </Button>
          <Button onClick={handleConfirm} disabled={!amount}>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!successMessage || !!errorMessage}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={successMessage || errorMessage}
      />

      {data ? (
        <UserTable data={data.user} />
      ) : (
        <Typography variant="body1" component="p" align="center">
          Error loading user data.
        </Typography>
      )}
      <Box
        sx={{
          textAlign: "center",
          mt: 2,
        }}
      >
        <Button variant="contained" onClick={handleClickOpen}>
          Send Money
        </Button>
      </Box>
    </section>
  );
};

export default ClientsProfile;
